/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export class ObjectHelper {    
  
  // Check if two objects are equal
  static equals(object1, object2): boolean {
    return JSON.stringify(object1) === JSON.stringify(object2);
  }

  // Check if an object has a property
  static has(object, property): boolean {
    return property in object;
  }

  static isEmpty(obj: any): boolean {
    return (obj === '' || obj === null || obj === undefined) ||
      ( Array.isArray(obj) && obj.length === 1 && obj[0] === '' );
  }

  // Check if an object is empty
  static isEmptyObj(obj: any): boolean {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  static clone<T>(object: T): T {
    const json = JSON.stringify(object);
    return JSON.parse(json);
  }

}
