import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable, switchMap } from 'rxjs';

import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

import { environment } from '../../../environments/environment';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private secureRoutes = [
    environment.services.assetApi,
    environment.services.catalogApi,
    environment.services.idpApi,
    environment.services.notificationsApi,
    environment.services.notificationsHub,
    environment.services.oneHubApi,
    environment.services.portalApi,
    environment.services.referenceApi,
    environment.services.autocareApi,
    environment.services.searchApi,
  ];

  constructor(
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Ensure we send the token only to routes which are secured
    if (!this.secureRoutes.find((x) => req.url.startsWith(x))) {
        return next.handle(req);
    }

    let request = req;

    const authToken = this.oktaAuth.getAccessToken();
    if (!authToken) {
      return next.handle(request);
    }

    request = req.clone({ setHeaders: { 'Authorization': `Bearer ${authToken}` } });
    return next.handle(request);      
  }
}

