// Environment variables configured in assets/envconf.js
declare var authority: string;
declare var baseUri: string;
declare var portalApi: string;
declare var notificationsApi: string;
declare var notificationsHub: string;
declare var catalogApi: string;
declare var assetsApi: string;
declare var referenceApi: string;
declare var oneHubApi: string;
declare var idpApi: string;
declare var autocareApi: string;
declare var searchApi: string;
declare var environmentLabel: string;
declare var environmentLabelVisible: boolean;
declare var idleTimeoutInMinutes: number;
declare var agGridLicenseKey: string;
declare var oktaDomain: string;
declare var oktaClientId: string;
declare var okta: boolean;


export const environment = {
  production: false,
  environmentLabel,
  environmentLabelVisible,
  idleTimeoutInMinutes,
  agGridLicenseKey,
  oktaDomain,
  oktaClientId,
  okta,

  services: {
    baseUri,
    authority,
    portalApi,
    notificationsApi,
    notificationsHub,
    catalogApi,
    assetApi: assetsApi,
    referenceApi,
    oneHubApi,
    idpApi,
    autocareApi,
    searchApi,
  }
};
